import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../../service/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  userName;
  userRole;

  constructor(public authenticationService: AuthenticationService, private router: Router) {

  }

  // logout(){
  //   this.authenticationService.logout();
  // }
  // constructor(private router: Router) { }

  ngOnInit() {
    this.userName = localStorage.getItem('velocityUserTokenName');
    this.userRole = localStorage.getItem('velocityUserTokenRole');
  }

  logOut() {
    localStorage.setItem('velocityUserToken', '');
    localStorage.removeItem('velocityUserTokenName');
    localStorage.removeItem('velocityUserTokenRole');
    localStorage.removeItem('velocityUserAccess');
    localStorage.removeItem('velocityUserCurrency');
    this.router.navigate(['']);
  }

}
