import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { Access } from 'src/app/Common/access-right';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public tempURL: any;
  public filteredURL: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  access = new Access();

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          var isExists = false;
          this.resetMenuActive(this.menuItems);
          menuItems.filter(items => {
            this.filteredURL = event.url.split("?")[0];

            if (items.path === this.filteredURL) {
              this.setNavActive(items);
              isExists = isExists ||true;
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === this.filteredURL) {
                this.setNavActive(subItems);
                isExists = isExists ||true;
                sessionStorage.setItem('Last1stmenu', items.title);
                sessionStorage.setItem('Last2ndmenu', subItems.title);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === this.filteredURL) {
                  this.setNavActive(subSubItems);
                  isExists = isExists ||true;
                }
              });
            });
          });
          //console.log(event.url +" - "+ isExists);
          if(!isExists )
          {
            const lastMenuLevel = [sessionStorage.getItem('Last1stmenu') ,sessionStorage.getItem('Last2ndmenu')];
            var level = 0;
            var lastParentMenuNode:Menu = null;

            lastMenuLevel.forEach((lastMenu , index)=>
            {
              if( lastMenuLevel[level]!=null)
              {
                if(level ==0 )
                {
                  var matchedParent = menuItems.filter((parentItem)=>{ return  parentItem.title === lastMenuLevel[level];});

                  if(matchedParent.length>0)
                  {
                    lastParentMenuNode = matchedParent[0];
                    level++;
                    matchedParent[0].active = true;
                  }
                }
                else if(lastParentMenuNode!=null && lastParentMenuNode.children){
                  var matchedchildMenu = lastParentMenuNode.children.filter((childItem)=>{ return  childItem.title === lastMenuLevel[level];});

                  if(matchedchildMenu.length>0)
                  {
                    lastParentMenuNode = matchedchildMenu[0];
                    level++;
                    matchedchildMenu[0].active = true;
                  }


                }
              }
            
            });
          }
        }
      });
    });
  }

  resetMenuActive(menuList: Menu[])
  {
    menuList.forEach((item=>{
      item.active = false;

      if(item.children)
      {
        item.children.forEach((itemChild)=>{
          itemChild.active = false;
        });
      }
    }));

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true; 
        menuItem.children.filter(submenuItems => {  
          if (submenuItems ===  item){
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  
  allowAccess(accessId){
    return this.access.IsAccessible(accessId);
  }

  IsGroupAllow(accesslist){
    return this.access.IsGroupAccessible(accesslist);
  }
}
