export class GlobalConstants {
  public static apiURL: string = "https://itsolutionstuff.com/";

  public static siteTitle: string = "This is example of ItSolutionStuff.com";

  public static accessToken : string = "";

  public static accessTokenName : string = "";

  public static accessTokenRole : string = "";
}
