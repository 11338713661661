import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var require
const Swal = require('sweetalert2')

import { GlobalConstants } from '../../Common/global-constants';

@Injectable({
    providedIn: 'root'
})
export class BankSummaryService {
    // private REST_API_SERVER = "http://207.46.237.238:902/API";
    private REST_API_SERVER = environment.apiUrl;

    constructor(private httpClient: HttpClient) { }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if(error.status == 401){
            Swal.fire({
                title: 'Session Expired!',
                html: 'Please Login again',
                timer: 3000,
                confirmButtonText:"Back To Login"
              }).then((result) => {
                window.location.href = "";
              })
        }
        // window.alert(errorMessage);
        return throwError(errorMessage);
    }

    public getBankAccountList() {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/Summary?PageSize=100&PageNumber=0`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAccountListWithFilter(showPerPage, page, filterBankAccountCode, BankSelectedItems, ATSelectedItems, filterCurrency, StatusSelectedItems, filterVbotStatus, sortingType, reverseSortOrder, filterSet, filterToReview, filterDescription) {
        var BankCode:string = BankSelectedItems.map(bc => bc.Value).join(",");
        var AccountType:string = ATSelectedItems.map(at => at.Value).join(",");
        var Status:string = StatusSelectedItems.map(st => st.Value).join(",");
        
        filterBankAccountCode ? filterBankAccountCode = '&bankaccountcode=' + filterBankAccountCode : filterBankAccountCode = '';
        BankCode ? BankCode = '&BankCode=' + BankCode : BankCode = '';
        AccountType ? AccountType = '&AccountType=' + AccountType : AccountType = '';
        filterCurrency ? filterCurrency = '&Currency=' + filterCurrency : filterCurrency = '';
        Status ? Status = '&Status=' + Status : Status = '';
        filterVbotStatus ? filterVbotStatus = '&VBotStatus=' + filterVbotStatus : filterVbotStatus = '';
        filterSet ? filterSet = '&Remark=' + filterSet : filterSet = '';
        filterToReview ? filterToReview = '&ToReview=' + filterToReview : filterToReview = '';
        sortingType ? sortingType = '&SortingType=' + sortingType : sortingType = '';
        reverseSortOrder ? reverseSortOrder = '&ReverseOrder=' + reverseSortOrder : reverseSortOrder = '';
        filterDescription ? filterDescription = '&Description=' + filterDescription : filterDescription = '';

        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/Summary?PageSize=${showPerPage}&PageNumber=${page}${filterBankAccountCode}${BankCode}${AccountType}${filterCurrency}${Status}${filterVbotStatus}${sortingType}${reverseSortOrder}${filterSet}${filterToReview}${filterDescription}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAccountDetail(accountCode) {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/CodeCheck?BankAccountCode=${accountCode}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`
            }
        }).pipe(catchError(this.handleError));
    }

    public addNewBank(data) {
        var post =
        {
            "BankAccountCode": data.BankAccountCode,
            "Currency": data.Currency,
            "BankCode": data.BankCode,
            "AccountType": data.AccountType,
            "AccountName": data.BankAccountName,
            "AccountNumber": data.AccountNumber,
            "AccountNameAbb": null,
            "CreditLimit": data.CreditLimit,
            "Status": data.Status,
            "Threshold": data.Threshold,
            "UserName": data.Username,
            "Password": data.Password,
            "CurrentBalance": data.CurrentBalance,
            "AvailableBalance": data.AvailableBalance,
            "OpeningBalance": data.OpeningBalance,
            "Remark": data.Remark,
            "Description": data.Description
        }
        return this.httpClient.post(`${this.REST_API_SERVER}/BankAccount/Add`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public editBank(data) {
        var post = {
            "BankAccountCode": data.BankAccountCode,
            "Currency": data.Currency,
            "BankCode": data.BankCode,
            "AccountType": data.AccountType,
            "PGName": data.PGName,
            "AccountGroup": data.AccountGroup,
            "AccountName": data.BankAccountName,
            "AccountNumber": data.AccountNumber,
            "Abbreviation": null,
            "CreditLimit": data.CreditLimit,
            "Status": data.Status,
            "Threshold": data.Threshold,
            "CurrentBalance": data.CurrentBalance,
            "AvailableBalance": data.AvailableBalance,
            "OpeningBalance": data.OpeningBalance,
            "Remark": data.Remark,
            "Description": data.Description
        }

        return this.httpClient.put(`${this.REST_API_SERVER}/BankAccount/Edit`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public editBankLoginDetail(data) {
        var post = {
            "Username": data.Username,
            "Password": data.Password,
            "AccountCode": data.AccountCode
        };
        return this.httpClient.put(`${this.REST_API_SERVER}/BankAccount/EditLoginDetail`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public checkBankAccountDuplicateApi(accountCode) {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/CheckDuplicate?BankAccountCode=${accountCode}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankCodeApi() {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/BankCode`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankCodeByCurrencyApi(bankCode) {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/BankCodeByCurrency?Currency=${bankCode}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAccountLog(accountNumber, pagesize, pageNumber) {
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/Log?AccountNumber=${accountNumber}&PageSize=${pagesize}&PageNumber=${pageNumber}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public runAccount(acc, bank) {
        return this.httpClient.post(`${this.REST_API_SERVER}/BankAccount/RunAccount`, { accountNumber: acc, BankCode: bank }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            observe: 'response'
        }).pipe(catchError(this.handleError));

    }

    public updateStatus(post) {
        return this.httpClient.post(`${this.REST_API_SERVER}/BankAccount/EditStatus`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            observe: 'response'
        }).pipe(catchError(this.handleError));
    }

    public importExcel(formData) {
        return this.httpClient.post(`${this.REST_API_SERVER}/BankAccount/ExcelRead`, formData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`
            }
        }).pipe(catchError(this.handleError));
    }

    public SaveImport(batchId,itemList) {
        var post = {
            BatchId : batchId,
            ItemIdList: itemList
        }
        return this.httpClient.post(`${this.REST_API_SERVER}/BankAccount/Import`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public pauseVBot(accNo, action){
        var data = {
            AccountNumber : accNo,
            Action: action
        }
        return this.httpClient.put(`${this.REST_API_SERVER}/BankAccount/PauseVBot`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError)); 
    }

    public getVBotStatusByAccountStatusApi(statusValue){
        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/GetVBotStatusByBankStatus?StatusValue=${statusValue}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticator(filter) {
        return this.httpClient.get(`${this.REST_API_SERVER}/Authenticator/Listing?PageSize=${filter.pagesize}&PageNumber=${filter.page}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public updateBankAuthenticatorStatus(post) {
        return this.httpClient.put(`${this.REST_API_SERVER}/Authenticator/EditStatus`, post, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'Content-Type': 'application/json',
                "accept": "application/json"
            },
            observe: 'response'
        }).pipe(catchError(this.handleError));
    }

    public deleteBankAuthenticator(id) {
        return this.httpClient.delete(`${this.REST_API_SERVER}/Authenticator/Delete?RowId=${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticatorDetail(id) {
        return this.httpClient.get(`${this.REST_API_SERVER}/Authenticator/AuthenticatorDetail?RowId=${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public updateBankAuthenticatorDetail(data) {
        return this.httpClient.put(`${this.REST_API_SERVER}/Authenticator/EditInfo`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    public addBankAuthenticator(data) {
        return this.httpClient.post(`${this.REST_API_SERVER}/Authenticator/Add`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).pipe(catchError(this.handleError));
    }

    // Added by Jack - Export function
    public exportApi(showPerPage, page, filterBankAccountCode, BankSelectedItems, ATSelectedItems, filterCurrency, StatusSelectedItems, filterVbotStatus, sortingType, filterSet) {
        var BankCode:string = BankSelectedItems.map(bc => bc.Value).join(",");
        var AccountType:string = ATSelectedItems.map(at => at.Value).join(",");
        var Status:string = StatusSelectedItems.map(st => st.Value).join(",");

        filterBankAccountCode ? filterBankAccountCode = '&AccountCode=' + filterBankAccountCode : filterBankAccountCode = '';
        BankCode ? BankCode = '&BankCode=' + BankCode : BankCode = '';
        AccountType ? AccountType = '&AccountType=' + AccountType : AccountType = '';
        filterCurrency ? filterCurrency = '&Currency=' + filterCurrency : filterCurrency = '';
        Status ? Status = '&Status=' + Status : Status = '';
        filterVbotStatus ? filterVbotStatus = '&VBotStatus=' + filterVbotStatus : filterVbotStatus = '';
        filterSet ? filterSet = '&Remark=' + filterSet : filterSet = '';
        sortingType ? sortingType = '&SortingType=' + sortingType : sortingType = '';

        return this.httpClient.get(`${this.REST_API_SERVER}/BankAccount/Export?PageSize=${showPerPage}&PageNumber=${page}${filterBankAccountCode}${BankCode}${AccountType}${filterCurrency}${Status}${filterVbotStatus}${sortingType}${filterSet}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/vnd.ms-excel',
            }, responseType: 'blob',
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticatorOTP(id, action) {
        return this.httpClient.get(`${this.REST_API_SERVER}/Authenticator/Otp?RowId=${id}&Action=${action}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticatorOTPLog(id, pageNo, pageSize) {
        return this.httpClient.get(`${this.REST_API_SERVER}/Authenticator/OtpLog?RowId=${id}&PageSize=${pageSize}&PageNumber=${pageNo}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticatorDropdown() {
        return this.httpClient.get(`${this.REST_API_SERVER}/Authenticator/DropdownList`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
            }
        }).pipe(catchError(this.handleError));
    }

    public getBankAuthenticatorOTPLogAll(data) {
        return this.httpClient.post(`${this.REST_API_SERVER}/Authenticator/OtpLogAll`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('velocityUserToken')}`,
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).pipe(catchError(this.handleError));
    }
}