import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { BankSummaryService } from '../../../../service/bank-summary/bank-summary.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
  providers: [NgbModalConfig, NgbModal, NgbActiveModal]
})
export class OtpModalComponent {
  @ViewChild('otpContent', { read: TemplateRef }) otpModalContent: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  modalReference: any;
  otp = null;
  loaded = false;
  id = null;
  name = null;
  action = 0;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private bankSummaryService: BankSummaryService, private toastr: ToastrService) { }
  
  ngOnInit(): void {
  }
  
  openModal(id, name, action) {
    this.id = id;
    this.name = name;
    this.loaded = false;
    this.otp = null;
    this.action = action;
    
    this.modalReference = this.modalService.open(this.otpModalContent, { centered: true });
    this.getOTP();
  }

  getOTP() {
    this.bankSummaryService.getBankAuthenticatorOTP(this.id, this.action)
    .pipe(takeUntil(this.destroy$), finalize(()=>{
      this.copyToClipboard(this.otp);
    }))
    .subscribe((data: any) => {
      this.loaded = true;
      this.otp = data.Otp;
    })
  }

  copyToClipboard(mss) {
    navigator.clipboard.writeText(mss);       
    this.toastr.success('Copied!')
  }

}
