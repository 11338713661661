<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt=""> -->
  <div class="profile_pic"><i class="icofont icofont-ui-user"></i></div>
  <div class="media-body">
    <div class="d-flex">
      <div class="userData">
        <span>{{userName}}</span>
        <p class="userrole">{{userRole}}</p>
      </div>
      <div class="iconDrop"><i class="middle fa fa-angle-down"></i></div>
    </div>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <li><a href="#" routerLink='/dashboard/user-profile'><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Profile</span></a></li>
  <li>
    <a (click)="logOut()">
      <app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log Out</span>
    </a>
  </li>
</ul>