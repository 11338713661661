export class Access {
    public AccessRight: Array<string>;
    public Currency: Array<string>;

    private getAccessList(): Array<string> {
        var stored = localStorage.getItem('velocityUserAccess');
        if (stored != null) {
            return JSON.parse(stored);
        }
        else {
            return [];
        }
    }

    private getCurrency(): Array<string> {
        var stored = localStorage.getItem("velocityUserCurrency");
        if (stored != null) {
            return JSON.parse(stored);
        }
        else {
            return [];
        }
    }

    constructor() {
        this.AccessRight = this.getAccessList();
        this.Currency = this.getCurrency();
    }

    public IsAccessible(access): Boolean {
        var allowed = false;
        if (this.AccessRight != null && this.AccessRight.length > 0 && this.AccessRight.indexOf(access) >= 0) {
            allowed = true
        }
        return allowed;
    }

    public IsGroupAccessible(accesslist: Array<string>): Boolean {
        var accessRight = this.AccessRight;
        var allowed = accesslist.some(function (value) {
            return accessRight != null && accessRight.length > 0 && accessRight.indexOf(value) >= 0;
        });
        return allowed;
    }

    public hasCurrency(currency): Boolean {
        var currencyList = this.Currency;
        var allow = false;
        if (currencyList != null && currencyList.length > 0 && currencyList.indexOf(currency) >= 0) {
            allow = true;
        }
        return allow
    }
}

export const AccessList = {
    View_User_List: '001_01_01',
    Add_User: '001_02_01',
    Edit_User: '001_03_01',
    Delete_User: '001_04_01',
    Reset_User_Password: '001_05_01',
    View_User_Log: '001_06_01',

    View_Bank_Summary_List: '002_01_01',
    Export_Bank_Account: '002_01_02',
    Add_Bank_Account: '002_02_01',
    Edit_Bank_Account: '002_03_01',
    View_Bank_Account:'002_03_03',
    Pause_Bot: '002_03_02',//ask
    Edit_Bank_VBotStatus:'002_03_04',
    Run_Account: '002_04_01',//bot trigger
    View_Bank_Account_Log: '002_05_01',
    View_Bank_Detail: '002_06_01',
    Import_Bank_Account: '002_07_01',
    View_Bank_LoginDetail : '002_08_01',
    Edit_Bank_LoginDetail : '002_09_01',
    Edit_Bank_ResumeVBot : '002_10_01',
    Edit_Bank_PauseVBot : '002_10_02',

    View_Transaction_History: '003_01_01',
    Edit_Purpose: '003_02_01',
    DP_Claim_Approval: '003_03_01',
    Reverse_DP_Transaction: '003_03_02',
    WD_Claim_Approval: '003_03_03',
    Reverse_WD_Transaction: '003_03_04',
    View_Transaction_Log: '003_04_01',
    Delete_Transaction: '003_05_01',
    Import_Transaction_History: '003_06_01',
    Export_Transaction_History: '003_07_01',

    View_User_Group: '004_01_01',
    Add_User_Group: '004_02_01',
    Edit_User_Group: '004_03_01',
    Delete_User_Group: '004_04_01',

    View_Workers: '005_01_01',
    Add_Workers: '005_02_01',
    Edit_Workers: '005_03_01',
    Set_On_Off_Workers: '005_04_01',
    Delete_Workers: '005_05_01',
    View_Workers_Log: '005_06_01',
    Reset_Bank_Count: '005_07_01',
    Worker_IP_Whitelist : '005_08_01',
    Update_Worker_IP_Whitelist : '005_08_02',

    //Authenticator:"007_00_00",
    View_Authenticator:"007_01_01",
    View_SecretKey_Authenticator:"007_02_01",
    Add_Authenticator:"007_03_01",
    Edit_Authenticator:"007_04_01",
    Delete_Authenticator:"007_05_01",
    Generate_OTP_Authenticator:"007_06_01",
    View_OTP_Log_Authenticator:"007_07_01",
    Authenticator_Log:"007_08_01",

    View_PGDeposit: '008_01_01',
    Approve_PGDeposit: '008_02_01',
    Reject_PGDeposit: '008_03_01',
    PGBot_Trigger: '008_04_01',
    PGOverride_Deposit: '008_05_01',
    Export_CSV_PGDeposit: '008_06_01',
    View_Log_PGDeposit: '008_07_01',

    View_PGWithdrawal: '009_01_01',
    View_PGMain_Transaction: '009_02_01',
    View_PGMain_Transaction_Details: '009_02_02',
    PGAcknowledge_Withdrawal: '009_03_01',
    Approve_PGWithdrawal: '009_04_01',
    RemapApprove_PGWithdrawal: '009_11_01',
    Reject_PGWithdrawal: '009_05_01',
    Reverse_PGWithdrawal: '009_06_01',
    Export_CSV_PGWithdrawal: '009_07_01',
    View_Log_PGWithdrawal: '009_08_01',
    Adjust_PGWithdrawal: '009_09_01',
    View_All_PGPayout_details: '009_10_01',
    PGPayout_Details: '009_10_02',

    View_Merchant_Account: '010_01_01',
    Edit_Merchant_Account: '010_01_02',
    View_Setting_Info: '010_01_03',
    Edit_Setting_Info: '010_01_04',
    View_Settlement: '010_01_05',
    Create_Settlement: '010_01_06',
    View_Adjustment: '010_01_07',
    Create_Adjustment: '010_01_08',
    View_Cash_Log: '010_01_09',
    Export_Cash_Log: '010_01_10',
    View_Merchant_BankAccount: '010_01_11',
    Edit_Merchant_BankAccount: '010_01_12',
    View_Audit_Log: '010_01_13',
    View_Settlement_Management: '010_02_01',
    Approve_Reject_Settlement: '010_02_02',
    Export_Settlement: '010_02_03',
    Settlement_Bot_Trigger: '010_02_04',
    Settlement_Overide: '010_02_05',
    View_Adjustment_Management: '010_03_01',
    Approve_Reject_Adjustment: '010_03_02',
    Export_Adjustment: '010_03_03',
    Generate_Login_OTP: '010_04_01',
    Generate_Settlement_OTP: '010_04_02',

    View_Fee_Setting: '011_01_01',
    Edit_Fee_Setting: '011_01_02',
    View_Payment_Type: '011_02_01',
    Add_Payment_Type: '011_02_02',
	Edit_Payment_Type: '011_02_03',
	Delete_Payment_Type: '011_02_04',
    View_Bank_Management: '011_03_01',

    View_Bank_Account_Group: '012_01_01',
    Add_Bank_Account_Group: '012_01_02',
	Edit_Bank_Account_Group: '012_01_03',
	Delete_Bank_Account_Group: '012_01_04',
    Bank_Account_Group_Log: '012_01_05'
}