<ng-template #otpContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">
            Generate OTP
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="loader-box" *ngIf="!loaded; else loadedBlock">
            <div class="loader-3"></div>
        </div>
        <ng-template #loadedBlock>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label><b>{{name}}</b></label>
                        <div class="form-control">
                            <div class="row">
                                <div class="col-11"><label>{{otp}}</label></div>
                                <div (click)="copyToClipboard(otp)"><i class="fa fa-copy"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>